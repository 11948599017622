<template>
    <a-layout>
        <a-layout-header style="background: #fff; padding: 0" />
        <a-layout-content style="margin: 0 16px">
            <a-breadcrumb style="margin: 16px 0">
                <a-breadcrumb-item>首页</a-breadcrumb-item>

            </a-breadcrumb>
            <a-descriptions title="用户信息">
                <a-descriptions-item label="用户uid">
                    {{uid}}<br/>
                    {{token}}<br/>
                    {{name}}<br/>
<!--                    {{Doctordata}}<br/>-->
<!--                    {{resourcedata}}<br/><br/>-->
<!--                    {{itemdata}} -->
                </a-descriptions-item>

            </a-descriptions>

        </a-layout-content>
        <a-layout-footer style="text-align: center">

        </a-layout-footer>

    </a-layout>
</template>

<script>

    // import { Modal } from "ant-design-vue";
    // import Storage from "../common/storage";

    import Storage from "../common/storage";

    export default {
        name: "Dashboard",
        components: {},
        data() {
            return {
                data: [],
                // Doctordata: Storage.Doctordata,
                // itemdata: Storage.itemdata,
                // resourcedatalist: Storage.resourcedatalist,
                // itemdatalist: Storage.itemdatalist,
            };
        },
        created() {
            this.uid = Storage.uid;
            this.token = Storage.token;
            this.name = Storage.myName;

            console.log("uid",this.uid);
            console.log("token",this.token);
        },
        methods: {

        }
    };
</script>
<style scoped>
    .editable-row-operations a {
        margin-right: 8px;
    }
    #components-layout-demo-custom-trigger .trigger {
        font-size: 18px;
        line-height: 64px;
        padding: 0 24px;
        cursor: pointer;
        transition: color 0.3s;
    }

    #components-layout-demo-custom-trigger .trigger:hover {
        color: #1890ff;
    }

    #components-layout-demo-custom-trigger .logo {
        height: 32px;
        background: rgba(255, 255, 255, 0.2);
        margin: 16px;
    }
    .serach_input {
        width: 25vw;
        float: right;
        margin-top: 10px;
        margin-bottom: 10px;
        text-align: right;
        margin-right: 25px;
        z-index: 1000;
    }
    .pages {
        float: right;
        margin-top: 10px;
    }
    .toolsbar {
        display: block;
        width: 100%;
        height: 42px;
        background: #fff;
    }
</style>
